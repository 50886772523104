// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import calenderReducer from "./calenderReducer";
// import chatReducer from "./chatReducer";
import chatReducer from "./chat/";
import contactsReducer from "./contacts/";
import customizer from "./customizer/";
import user from "./optimize100x/user";
import vessel from "./seanet/vessel";
import quickSearch from "./optimize100x/quickSearch";

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
  //Apex reducers
  calender: calenderReducer,
  contactApp: contactsReducer,
  toastr: toastrReducer, // <- Mounted at toastr.
  chatApp: chatReducer,
  customizer: customizer,
  //Optimize100x reducers
  user,
  //SeaNet reducers
  vessel,
  quickSearch
});

export default rootReducer;
